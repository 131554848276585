<template>
  <div class="checkin-view">
    <div class="main-panel">
      <b-alert :show="showErrorAlert" dismissible variant="danger" class="mx-3"
        >{{errorMessage}}</b-alert>

      <PageHeaderKiosk/>
      <div class="text-center">
        <h1>Edit Player Profile</h1>
      </div>
      <div class="container-fluid">
        <div class="mt-2 mb-4 pt-2"  style="text-align: left">
          <!-- <div class="mb-2" style="text-align: left">
            <label for="customId">RFID:</label>
            <input v-model="customId" class="form-control" id="customId" placeholder="RFID" disabled>
          </div> -->
          <label for="displayName">Nickname:</label>
          <div class="mb-2 input-group">
            <input v-model="displayName" class="form-control" id="displayName" 
              placeholder="Nickname" maxlength="20">
            <div class="input-group-append">
              <!-- <button class="btn btn-outline-secondary" type="button" 
                @click="onNicknameRandom()">Random</button> -->
              <button class="btn btn-outline-secondary" type="button" 
                @click="onNicknameByName()">By Name</button>
            </div>
          </div>
          <!-- <label for="firstName" style="text-align: left">First and Last Name:</label>
          <div class="mb-2 input-group">
            <input v-model="firstName" class="form-control" id="firstName" 
              placeholder="First name" maxlength="50">
            <input v-model="lastName" class="form-control" id="lastName" 
              placeholder="Last name" maxlength="50">
          </div> -->
        </div>
        <button type="button" class="btn btn-success btn-large btn-block my-2"
          @click="onSave()"><h3>Save</h3></button>
        <button type="button" class="btn btn-secondary btn-large btn-block my-2"
          @click="onCancel()"><h3>Cancel</h3></button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeaderKiosk from '@/components/PageHeaderKiosk.vue'
import { getRandomInt } from '@/common/number.helper';
import { StringUtils } from '@/common/string.utils';
import { PLAYERS_SET_PLAYER, PLAYERS_SAVE_KIOSK_PLAYER, PLAYERS_ERROR, PLAYERS_SUCCESS } 
  from '@/store/modules/players.store'
import { PlayersService } from '@/services/players.service';

export default {
  name: 'PlayerEditView',
  components: {
    PageHeaderKiosk,
  },
  data() {
    return {
      id: null,
      customId: '',
      displayName: '',
      firstName: '',
      lastName: '',
      player: null,
    }
  },
  created() {
    // this.id = this.$route.params.id;
    this.getPlayer();
    if (!this.id) {
      this.$router.push(`/home`)
    }
  },
  computed: {
    showErrorAlert() {
      return (this.$store.state.players.error? true : false);
    },
    errorMessage() {
      return this.$store.state.players.error;
    },
  },
  methods: {
    getPlayer() {
      // get the player that has been logged in in the $store from the last
      // player scan.
      this.player = JSON.parse(JSON.stringify(this.$store.state.players.player));
      this.id = this.player._id;
      this.customId = this.player.customId;
      this.displayName = this.player.displayName;
      this.firstName = this.player.firstName;
      this.lastName = this.player.lastName;
    },
    async onNicknameRandom() {
      const res = await PlayersService.getDisplayName(3);
      if (res.data.displayName) {
        this.displayName = res.data.displayName;
      }
    },
    onNicknameByName() {
      const first = this.firstName? this.firstName : '';
      const last = this.lastName? this.lastName.charAt(0) : '';
      let numStr = '';
      if (first + last) {
        numStr = ('' + getRandomInt(0, 999)).padStart(3, '0');
      }
      const nickname = `${first}${last}${numStr}`;
      if (nickname) {
        this.displayName = nickname;
      }
    },
    checkValidNickname(value) {
      let isValid = true;
      if (!StringUtils.isValidUsername(value)) {
        this.$store.commit(PLAYERS_ERROR, 'Nickname can only contain letters, numbers, and underscores.');
        isValid = false;
      } else if (PlayersService.isProfane(value)) {
        this.$store.commit(PLAYERS_ERROR, 'Nickname contains inappropriate words; please choose another.');
        isValid = false;
      }
      return isValid;
    },
    onSave() {
      this.$store.commit(PLAYERS_SUCCESS);
      if (!this.checkValidNickname(this.displayName)) {
        return;
      }
      this.player.id = this._id;
      this.player.displayName = this.displayName.trim();
      this.player.firstName = this.firstName? this.firstName.trim() : '';
      this.player.lastName = this.lastName? this.lastName.trim() : '';
      
      this.$store.commit(PLAYERS_SET_PLAYER, this.player);
      this.$store.dispatch(PLAYERS_SAVE_KIOSK_PLAYER)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    onCancel() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped>
h1 {
  text-transform: uppercase;
}
</style>
